.card-scanner__preview {

    position: relative;
    overflow: hidden;

    width: 100%;
    aspect-ratio: 1/1;

    max-width: 400px;
}

/* draw a red square set in about 10% on each side as the "line up" thingy */
.card-scanner__preview::after {
    content: "";
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    border: 2px solid red;
    border-radius: 10px;
    /* Darken around the frame */
    box-shadow: 0px 0px 100rem 100rem rgba(0, 0, 0, 0.7);
    z-index: 2;
}

.card-scanner__preview__video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
}

.card-scanner__preview--enable-camera {
    position: absolute;
    text-align: center;
    width: 100%;
    padding: 10%;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 1;
}