@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

body {
    @apply bg-gray-800;
    margin: 0;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
}

.btn {
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;

    border: 3px solid rgb(0, 0, 0);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
}