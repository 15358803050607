.virtual-card {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.virtual-card__footer {
    margin-top: 20px;
    font-size: 12px;
    color: #aaa;
}


.virtual-card__qr {
    margin: 10px;
    padding: 20px;
    border-radius: 13px;
    background-color: #d59027;

    display: flex;
    flex-direction: column;
    border: 3px solid black;
    align-items: center;

    max-width: 100vw;
}

.virtual-card__qr__label {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.virtual-card__qr__code {
    max-width: calc(100vw - 80px);
    max-height: calc(100vw - 80px);

    background-color: #fff;
    padding: 10px;
    border-radius: 10px;

    border: 3px solid black;
}

#installInstructions {
    display: none
}

@media (display-mode: browser) {
    #installInstructions {
        display: block
    }
}